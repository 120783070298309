import React, { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Typography, TextField, Box, InputAdornment, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const RightDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        margin: '0',
        width: '450px',
        transform: 'translateX(-50%)',
        left: '50%'
    }
}));

const Register = (props) => {
  const { open, handleClose, isLoading, handleSubmit, register, errors, setLoginOpen, setRegisterOpen } = props;
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <RightDialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
            <div>
                <Typography variant='h1'>My Account</Typography>
                <Typography variant='h6'>Open a Swag account and open yourself to success</Typography>
            </div>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </Box>
        <Box sx={{ maxWidth: "900px", p: 5 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel sx={{ fontSize: "14px", fontWeight: 500 }} htmlFor="firstName-login">
                    First Name *
                </InputLabel>
                <TextField
                    fullWidth
                    id="firstName-login"
                    name="firstName"
                    variant="outlined"
                    {...register('firstName', { required: true, pattern: /^\S+@\S+$/i })}
                    error={!!errors.firstName}
                    helperText={errors.firstName && 'Please enter a valid name'}
                />
                <InputLabel sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }} htmlFor="lsatName-login">
                    Last Name
                </InputLabel>
                <TextField
                    fullWidth
                    id="lsatName-login"
                    name="lastName"
                    variant="outlined"
                    {...register('lastName', { pattern: /^\S+@\S+$/i })}
                    error={!!errors.lastName}
                    helperText={errors.lastName && 'Please enter a valid name'}
                />
                <InputLabel sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }} htmlFor="email-login">
                    Email Address *
                </InputLabel>
                <TextField
                    fullWidth
                    id="email-login"
                    name="email"
                    variant="outlined"
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                    error={!!errors.email}
                    helperText={errors.email && 'Please enter a valid email address'}
                />
                <InputLabel sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }} htmlFor="password-login">Password *</InputLabel>
                <TextField
                    fullWidth
                    id="password-login"
                    name="password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', { required: true })}
                    error={!!errors.password}
                    helperText={errors.password && 'Password is a required field'}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                />
                <Box sx={{ display: "flex", mt: "5px" }}>
                    <Typography sx={{ cursor: "pointer" }} onClick={() => {setRegisterOpen(false); setLoginOpen(true)}}>Already have account?</Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                          disabled={Object.keys(errors).length > 0}
                      >
                        Login
                      </Button>
                        <Button
                        sx={{ my: 2 }}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => alert()}
                    >
                        Reset
                    </Button>
                </Box>
            </form>
        </Box>
      </RightDialog>
  );
};

export default Register;
