import { combineReducers } from 'redux';
import { userReducer as userPage } from './../api/user/userReducer';
import { categoryReducer as categoryPage } from "./../api/category/categoryReducer";
import { productReducer as productPage } from "./../api/product/productReducer";

const appReducer = combineReducers({
    userPage,
    categoryPage,
    productPage
});

export default appReducer;