import * as actionType from "./productActionType";

const initialState = {
  isLoading: false,
  product: {}
};

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_PRODUCT:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.FETCH_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                product: action.payload ? action.payload.data : {},
            };
        default:
            return state;
    }
}