import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Typography, TextField, Box, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const RightDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        margin: '0',
        width: '450px',
        transform: 'translateX(-50%)',
        left: '50%'
    }
}));

const Forgot = (props) => {
  const { open, handleClose, isLoading, handleSubmit, register, errors, reset, setForgotOpen, setLoginOpen } = props;
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <RightDialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
            <div>
                <Typography variant='h1'>Forgot Password</Typography>
                <Typography variant='h6'>We will send you a link to reset password please follow the instructions.</Typography>
            </div>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </Box>
        <Box sx={{ maxWidth: "900px", p: 5 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel sx={{ fontSize: "14px", fontWeight: 500 }} htmlFor="email-login">
                    Email Address *
                </InputLabel>
                <TextField
                    fullWidth
                    id="email-login"
                    name="email"
                    variant="outlined"
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                    error={!!errors.email}
                    helperText={errors.email && 'Please enter a valid email address'}
                />
                
                <Box sx={{ mt: "5px" }}>
                    <Typography sx={{ cursor: "pointer" }} onClick={() => {setForgotOpen(false); setLoginOpen(true);}}>Back to login</Typography>
                </Box>
                <Box sx={{ mt: 3 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                          disabled={Object.keys(errors).length > 0}
                      >
                        Send email
                      </Button>
                        <Button
                        sx={{ my: 2 }}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => reset()}
                    >
                        Reset
                    </Button>
                </Box>
            </form>
        </Box>
      </RightDialog>
  );
};

export default Forgot;
