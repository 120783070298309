import * as actionType from "./categoryActionType";

const initialState = {
  isLoading: false,
  filters: [],
  products: [],
  count: 0,
  category: {}
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_FILTER:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.FETCH_FILTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.FETCH_FILTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                filters: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_PRODUCTS:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.FETCH_PRODUCTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                products: action.payload ? action.payload.data.products : [],
                count: action.payload ? action.payload.data.count : 0,
                category: action.payload ? action.payload.data.category : {},
            };
        default:
            return state;
    }
}