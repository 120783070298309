import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './App/protected.route';
const DashboardContainer = lazy(() => import('./views/dashboard/dashboard.container'));
const DetailContainer = lazy(() => import('./views/detail/detail.container'));
const ListContainer = lazy(() => import('./views/list/list.container'));

export const RoutePath = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path='/' element={<DashboardContainer />} />
      <Route path='/product/:series/:categoryId/:groupId/:productId' element={<DetailContainer />} />
      <Route path='/category/:id' element={<ListContainer />} />
    </Routes>
  </Suspense>
);
