import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, IconButton, InputAdornment, FormControl, OutlinedInput, Typography, MenuList, MenuItem, ListItemText, Divider, List, ListItem, ListItemButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Header = ({ megaMenuOpen, setMegaMenuOpen }) => {
  const [open, setOpen] = useState(1);
  const navigate = useNavigate();
  const boxRef = useRef(null);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (boxRef.current && !boxRef.current.contains(event.target)) {
  //       setMegaMenuOpen(false);
  //     }
  //   };

  //   document.addEventListener("mouseleave", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mouseleave", handleClickOutside);
  //   };
  // }, []);
  return (
    <>
      <Grid container alignItems="center" sx={{ py: 2, px: 4 }}>
          <Grid item md={4} sx={{ display: "flex", gap: 3, alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }} onClick={() => setMegaMenuOpen(!megaMenuOpen)}>
              <Typography variant="subtitle2">Products</Typography>
              <KeyboardArrowDownIcon />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2">Insights</Typography>
              <KeyboardArrowDownIcon />
            </Box>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/"}>
                <img
                src={`${process.env.PUBLIC_URL}/assets/images/swag-logo.png`}
                alt="SWAG Logo"
                style={{ maxWidth: "200px" }}
                />
            </Link>
          </Grid>
          <Grid item md={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
              <OutlinedInput
                type="text"
                size="md"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Search"
              />
            </FormControl>
          </Grid>
      </Grid>
      {megaMenuOpen && (
        <Box
          onMouseLeave={() => setMegaMenuOpen(false)}
          sx={{ 
            width: "100%",
            position: "absolute",
            background: "#6B5A4B",
            px: "20px",
            zIndex: 9999,
            '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 50,
                  width: 30,
                  height: 30,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                }
          }}
        >
          <Grid container>
            <Grid item md={2} sx={{ background: "#796655", py: "30px" }}>
              <MenuList className="megamenu">
                <MenuItem sx={{ background: open == 1 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(1)}>
                  <ListItemText>Engagement Rings</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 2 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(2)}>
                  <ListItemText>Wedding Bands</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 3 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(3)}>
                  <ListItemText>Chain & Cords</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 4 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(4)}>
                  <ListItemText>Jewelery</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 5 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(5)}>
                  <ListItemText>Mountings</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 6 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(6)}>
                  <ListItemText>Findings</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 7 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(7)}>
                  <ListItemText>Diamonds</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 8 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(8)}>
                  <ListItemText>Lab-Grown Diamonds</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 9 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(9)}>
                  <ListItemText>GemStones</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 10 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(10)}>
                  <ListItemText>Metals</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem sx={{ background: open == 11 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(11)}>
                  <ListItemText>New</ListItemText>
                </MenuItem>
                <MenuItem sx={{ background: open == 12 ? "#6B5A4B" : "transparent" }} onMouseEnter={() => setOpen(12)}>
                  <ListItemText>3C Design</ListItemText>
                </MenuItem>
              </MenuList>
            </Grid>
          {open == 1 && (
            <Grid item md={10} p={4}>
              <Grid container spacing={5}>
                <Grid item md={9}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Style" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/21344")}>
                          <ListItemText primary="Solitaire" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/21347")}>
                          <ListItemText primary="Accented" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/21345")}>
                          <ListItemText primary="Halo-Style" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31903")}>
                          <ListItemText primary="Hidden Halo-Style" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/21350")}>
                          <ListItemText primary="Three-Stone" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/24064")}>
                          <ListItemText primary="Two-Stone" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/21336")}>
                            <ListItemText primary="Shop All" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Shape" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Round"]}]`)}>
                            <ListItemText primary="Round" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Round"]}]`)}>
                            <ListItemText primary="Oval" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Square"]}]`)}>
                            <ListItemText primary="Square" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Emerald"]}]`)}>
                            <ListItemText primary="Emerald" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Pear"]}]`)}>
                            <ListItemText primary="Pear" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Marquise"]}]`)}>
                            <ListItemText primary="Marquise" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Cushion"]}]`)}>
                            <ListItemText primary="Cushion" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Asscher"]}]`)}>
                            <ListItemText primary="Asscher" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Heart"]}]`)}>
                            <ListItemText primary="Heart" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"PrimaryStoneShape","matchType":"exact","options":["Triangle"]}]`)}>
                            <ListItemText primary="Triangle" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Aesthetic" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                              <ListItemText primary="Vintage-Inspired" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                              <ListItemText primary="Classic" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                              <ListItemText primary="Art Deco" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                              <ListItemText primary="Fancy" />
                          </ListItemButton>
                        </ListItem>
                      </List>

                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Product State" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"JewelryState","matchType":"exact","options":["Semi-Set"]}]`)}>
                              <ListItemText primary="Semi-Set" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21336?filters=[{"label":"JewelryState","matchType":"exact","options":["Unset"]}]`)}>
                              <ListItemText primary="Unset" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/21336`)}>
                            <ListItemText primary="Shop All Engagement Rings" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/22150`)}>
                            <ListItemText primary="New Engagement Rings" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/21336`)}>
                            <ListItemText primary="Bestsellers" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/engagement.jpg`} width="100%" alt="engagement" style={{ borderRadius: "10px" }}/>
                </Grid>
              </Grid>
            </Grid>
          )}
          {open == 2 && (
            <Grid item md={10} p={4}>
              <Grid container spacing={5}>
                <Grid item md={9}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                        <ListItemButton onClick={() => navigate(`/category/16083?filters=[{"label":"JewelryState","matchType":"exact","options":["N/A"]}]`)}>
                          <ListItemText primary="Metal Bands" />
                        </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Band Builder" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Classic Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/16316?filters=[{"label":"JewelryState","matchType":"exact","options":["N/A"]}]`)}>
                            <ListItemText primary="Fancy Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/15288`)}>
                            <ListItemText primary="Contemporary Bands" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Band Builder" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Half Round" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="European-Style" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Full Round" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Two-Tone Inlay" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Shop All" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/21269`)}>
                            <ListItemText primary="Diamond Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/22557`)}>
                            <ListItemText primary="Anniversary Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/5303`)}>
                            <ListItemText primary="Eternity Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/23204?filters=[{"label":"JewelryState","matchType":"exact","options":["Set","Unset"]}]`)}>
                            <ListItemText primary="Men’s Diamond Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/24049`)}>
                            <ListItemText primary="3C Customizable Designs" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/30159?filters=[{"label":"PrimaryStoneType","matchType":"like","options":["Lab-Grown"]}]`)}>
                            <ListItemText primary="Lab-Grown Diamond Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/16083?filters=[{"label":"PrimaryStoneType","matchType":"like","options":["Natural"]},{"label":"JewelryState","matchType":"exact","options":["Set"]}]`)}>
                            <ListItemText primary="Natural Diamond Bands" />
                          </ListItemButton>
                        </ListItem>
                      </List>

                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/5278`)}>
                            <ListItemText primary="Ring Enhancers" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/24079`)}>
                            <ListItemText primary="Contour Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/29583`)}>
                            <ListItemText primary="Wrap-Style Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/26230`)}>
                            <ListItemText primary="Ring Guards" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/15288`)}>
                            <ListItemText primary="Contemporary Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/15288?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["TUNGSTEN"]}]`)}>
                            <ListItemText primary="Tungsten" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/15288?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["TITANIUM"]}]`)}>
                            <ListItemText primary="Titanium" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/15288?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["COBALT"]}]`)}>
                            <ListItemText primary="Cobalt" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/15288`)}>
                            <ListItemText primary="Shop All" />
                          </ListItemButton>
                        </ListItem>
                      </List>

                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Collections" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/31902`)}>
                            <ListItemText primary="Cigar Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/31907`)}>
                            <ListItemText primary="Chevron Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/31426`)}>
                            <ListItemText primary="Sculptural Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/15288?filters=[{"label":"SettingType","matchType":"like","options":["Bezel-set"]}]`)}>
                            <ListItemText primary="Wood Inlay Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/21169?filters=[{"label":"SettingType","matchType":"like","options":["Bezel-set"]}]`)}>
                            <ListItemText primary="Bezel-Set Bands" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/30320`)}>
                            <ListItemText primary="Men’s Engagement Rings" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/16083`)}>
                            <ListItemText primary="Shop All Wedding Bands" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/22125`)}>
                            <ListItemText primary="New Wedding Bands" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemButton onClick={() => navigate(`/category/16083`)}>
                            <ListItemText primary="Bestsellers" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/engagement.jpg`} width="100%" alt="engagement" style={{ borderRadius: "10px" }}/>
                </Grid>
              </Grid>
            </Grid>
          )}
          {open == 3 && (
            <Grid item md={10} p={4}>
              <Grid container spacing={5}>
                <Grid item md={9}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Popular Styles" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Cable Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Rope Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Box Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Paperclip-Style Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Curb Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Wheat Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Rolo Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Figaro Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Bead Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Snake Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Anchor Chain" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Shop All" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Length" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Per Inch" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='7"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='8"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='16"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='18"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='20"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='22"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='24"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='28"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='30"' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary='Shop All' />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Metal Type" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["14"]}]`)}>
                            <ListItemText primary="Gold" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["14"]}]`)}>
                            <ListItemText primary="Gold-Filled" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["14"]}]`)}>
                            <ListItemText primary="Gold-Plated" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["PLAT"]}]`)}>
                            <ListItemText primary="Platinum" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["STER"]}]`)}>
                            <ListItemText primary="Silver" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["STER"]}]`)}>
                            <ListItemText primary="Steel" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089?filters=[{"label":"QualityCatalogValue","matchType":"like","options":["TITANIUM"]}]`)}>
                            <ListItemText primary="Titanium" />
                          </ListItemButton>
                        </ListItem>
                      </List>

                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Chain Components" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/13486`)}>
                            <ListItemText primary="Clasps" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/30264`)}>
                            <ListItemText primary="Chain Ends" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/13522`)}>
                            <ListItemText primary="Jump & Split Rings" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="New + Featured" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/28463`)}>
                            <ListItemText primary="New Products" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/3089`)}>
                            <ListItemText primary="Adjustable Chains" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate(`/category/31891`)}>
                            <ListItemText primary="Men's Chain" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/engagement.jpg`} width="100%" alt="engagement" style={{ borderRadius: "10px" }}/>
                </Grid>
              </Grid>
            </Grid>
          )}
          {open == 4 && (
            <Grid item md={10} p={4}>
              <Grid container spacing={5}>
                <Grid item md={9}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Type" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/11990")}>
                            <ListItemText primary="Rings" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/11991")}>
                            <ListItemText primary="Earrings" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/11992")}>
                            <ListItemText primary="Necklaces & Pendants" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/11993")}>
                            <ListItemText primary="Bracelets" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/3069")}>
                            <ListItemText primary="Chain & Cord" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31652")}>
                            <ListItemText primary="Charms" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/1635")}>
                            <ListItemText primary="Brooches & Pins" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/11996")}>
                            <ListItemText primary="Roses" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Category" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31571")}>
                            <ListItemText primary="Diamond Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/27002")}>
                            <ListItemText primary='Lab-Grown Diamond Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31572")}>
                            <ListItemText primary='Gemstone Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/26720")}>
                            <ListItemText primary='Birthstone Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31573")}>
                            <ListItemText primary='Metal Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/1638")}>
                            <ListItemText primary='Family Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/1637")}>
                            <ListItemText primary='Religious & Spiritual Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/24630")}>
                            <ListItemText primary='Personalized Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/24430")}>
                            <ListItemText primary='Pearl Jewelry' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/140")}>
                            <ListItemText primary="Men's Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/19769")}>
                            <ListItemText primary='Youth Jewelry' />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="Shop by Collection" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/26148")}>
                            <ListItemText primary="302® Fine Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31652")}>
                            <ListItemText primary="Charms & Pendants" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31526")}>
                            <ListItemText primary="Classic Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/28640")}>
                            <ListItemText primary="Stackable Rings" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31529")}>
                            <ListItemText primary="Freeform Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/28649")}>
                            <ListItemText primary="Heart Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31527")}>
                            <ListItemText primary="Infinity-Inspired Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/28643")}>
                            <ListItemText primary="Floral Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/28276")}>
                            <ListItemText primary="Zodiac Jewelry" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/28652")}>
                            <ListItemText primary="Words & Letters" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    
                    <Grid item md={3}>
                      <List dense={true} className="sub-category">
                        <ListItem className="head">
                          <ListItemText primary="New + Featured" />
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/22073")}>
                            <ListItemText primary="New Products" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton onClick={() => navigate("/category/31912")}>
                            <ListItemText primary="Color of the year" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/engagement.jpg`} width="100%" alt="engagement" style={{ borderRadius: "10px" }}/>
                </Grid>
              </Grid>
            </Grid>
          )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default Header;
