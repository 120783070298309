import { Grid, Typography, Link, Stack } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';

const typographyStyles = {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "29.44px",
    textAlign: "left",
    textDecoration: "none"
}

export default function Footer() {
    return (
        <footer style={{ backgroundColor: '#E5E5E5', padding: '50px 0', width: "100%" }}>
            <Grid container maxWidth="1580px" px={2} mx="auto" gap={4} pb={{ xs: 5, md: 10 }} direction={{ xs: "column", md: "row" }} flexWrap="wrap">
                <Grid item xs={2}>
                    <Typography variant="h6" gutterBottom>Account</Typography>
                    <Link href="#" underline='none' variant="body2" color="inherit">My Account</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Delivery Information</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">International Shipping</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Payment Options</Link>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" gutterBottom>Orders</Typography>
                    <Link href="#" underline='none' variant="body2" color="inherit">Check Order Status</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Return a Product</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Return Policies</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Shipping Carrier Information</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Order Cutoff Information</Link>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" gutterBottom>Information</Typography>
                    <Link href="#" underline='none' variant="body2" color="inherit">Careers</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Blog</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Offers & Contest Details</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">Help & FAQs</Link><br />
                    <Link href="#" underline='none' variant="body2" color="inherit">About Swag Jeweler</Link>
                </Grid>
            </Grid>
            <Grid container px={2} borderTop="1px solid #86715E" pt={5}>
                <Grid item xs={12}>
                    <Stack maxWidth="1580px" mx="auto" direction="row" alignItems="center" gap={5} justifyContent="space-between" width="100%" flexWrap={{ xs: "wrap", md: "nowrap" }}  >
                        <Stack direction="row" width="100%" alignItems="center" gap={2} flexWrap={{ xs: "wrap", md: "nowrap" }}>
                            <Link href="#" sx={typographyStyles} color="inherit">Privacy Policy</Link>
                            <Link href="#" underline='none' color="inherit">|</Link>
                            <Link href="#" sx={typographyStyles} color="inherit">2 Compliance</Link>
                            <Link href="#" underline='none' color="inherit">|</Link>
                            <Link href="#" sx={typographyStyles} color="inherit">Terms + Conditions</Link>
                            <Link href="#" underline='none' color="inherit">|</Link>
                            <Link href="#" sx={typographyStyles} color="inherit">Supplier Portal</Link>
                            <Typography sx={typographyStyles} variant="body2" align="center">
                                Copyright Swag Jeweller. All rights reserved
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={2} ml={{ md: "auto" }} flexWrap={{ xs: "wrap", md: "nowrap" }}>
                            <Link href="#" color="inherit"><FacebookIcon /></Link>
                            <Link href="#" color="inherit" style={{ marginLeft: '10px' }}><InstagramIcon /></Link>
                            <Link href="#" color="inherit" style={{ marginLeft: '10px' }}><YouTubeIcon /></Link>
                            <Link href="#" color="inherit" style={{ marginLeft: '10px' }}><TwitterIcon /></Link>
                            <Link href="#" color="inherit" style={{ marginLeft: '10px' }}><PinterestIcon /></Link>
                            <Link href="#" color="inherit" style={{ marginLeft: '10px' }}><LinkedInIcon /></Link>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </footer>
    );
}