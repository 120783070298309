export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const SET_IS_SAVED = "SET_IS_SAVED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
