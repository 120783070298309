import React, { useState, useEffect } from 'react';
import { Box, Grid, CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from './../../routes';
import { useForm, Controller } from 'react-hook-form';
import TopBar from './components/TopBar';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Forgot from './components/Forgot';

export default function AppRoot(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [megaMenuOpen, setMegaMenuOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const {
    handleSubmit: handleSubmitRegister,
    register: registerRegister,
    formState: { errors: registerErrors },
    reset: resetRegister,
    control:control,
  } = useForm({
    defaultValues: {},
    mode: 'onTouched'
  });
  const {
    handleSubmit: handleSubmitLogin,
    register: registerLogin,
    formState: { errors: loginErrors },
    reset: resetLogin
  } = useForm({
    defaultValues: {},
    mode: 'onTouched',
  });
  const {
    handleSubmit: handleSubmitForgot,
    register: registerForgot,
    formState: { errors: forgotErrors },
    reset: resetForgot
  } = useForm({
    defaultValues: {},
    mode: 'onTouched',
  });

  useEffect(() => {
    setMegaMenuOpen(false);
  }, [location]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Login
        open={loginOpen}
        handleClose={() => {
          setLoginOpen(false);
          resetLogin();
        }}
        isLoading={false}
        handleSubmit={handleSubmitLogin}
        register={registerLogin}
        errors={loginErrors}
        reset={resetLogin}
        setRegisterOpen={setRegisterOpen}
        setLoginOpen={setLoginOpen}
        setForgotOpen={setForgotOpen}
      />
      <Register
        open={registerOpen}
        handleClose={() => {
          setRegisterOpen(false);
          resetRegister();
        }}
        isLoading={false}
        handleSubmit={handleSubmitRegister}
        register={registerRegister}
        errors={registerErrors}
        reset={resetRegister}
        setRegisterOpen={setRegisterOpen}
        setLoginOpen={setLoginOpen}
      />
      <Forgot
        open={forgotOpen}
        handleClose={() => {
          setForgotOpen(false);
          resetForgot();
        }}
        isLoading={false}
        handleSubmit={handleSubmitForgot}
        register={registerForgot}
        errors={forgotErrors}
        reset={resetForgot}
        setForgotOpen={setForgotOpen}
        setLoginOpen={setLoginOpen}
      />
      <Grid container>
        <Grid container sx={{ borderBottom: "1px solid #eaeaea" }}>
          <Grid item sm={12}>
            <TopBar setLoginOpen={setLoginOpen}/>
          </Grid>
          <Grid item sm={12}>
            <Header megaMenuOpen={megaMenuOpen} setMegaMenuOpen={setMegaMenuOpen}/>
          </Grid>
          <Grid item sm={12}>
            {/* <Navlist /> */}
          </Grid>
        </Grid>
        <Grid container sx={{ px: "30px" }}>
          <RoutePath />
        </Grid>
        <Grid container>
            <Footer />
        </Grid>
     </Grid>
    </Box>
  );
}