import React from "react";
import { AppBar, Toolbar, Divider, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from '@mui/icons-material/Favorite';

const TopBar = ({ setLoginOpen }) => {
  return (
    <>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none"
        }}>
        <Toolbar
          className="some-class"
          sx={{
            justifyContent: "space-between",
            width: "100%",
            mx: "auto",
            minHeight: "30px!important"
          }}>
          <Link
            style={{
              color: "#fff",
              marginRight: 2,
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}>
            <CallIcon />
            800-888-8888
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Divider orientation="vertical" flexItem sx={{ background: "#FFF" }}/>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "1px",
                py: 1,
                px: 4
              }}>
                <ShoppingCartIcon />
                <Link
                  to="#"
                  style={{ color: "#fff", textDecoration: "none", lineHeight: "100%", fontSize: "14px", fontWeight: "bold" }}
                > Cart </Link>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ background: "#FFF" }}/>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "1px",
                px: 4
              }}>
                <FavoriteIcon />
                <Link
                  to="#"
                  style={{ color: "#fff", textDecoration: "none", lineHeight: "100%", fontSize: "14px", fontWeight: "bold" }}
                > Wishlist </Link>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ background: "#FFF" }}/>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "1px",
                pl: 4
              }}
              onClick={() => setLoginOpen(true)}
            >
                <PersonIcon />
                <Link
                  style={{ color: "#fff", textDecoration: "none", lineHeight: "100%", fontSize: "14px", fontWeight: "bold" }}
                > Account </Link>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
