import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import createCustomTheme from './../theme/theme';
import store from './store';
import { Provider } from 'react-redux';
import { AppRootContainer } from './app-root/app-root.container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';

function App() {
  const fetchThemeColors = () => {
    // Implement your logic to fetch theme colors from the database or external source
    return {
      primaryColor: '#AC7E11', // Fetch primary color from the database
      secondaryColor: '#dadada', // Fetch secondary color from the database
    };
  };
  
  const [themeColors, setThemeColors] = useState(null);
  
  useEffect(() => {
    const colors = fetchThemeColors();
    setThemeColors(colors);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeColors ? createCustomTheme(themeColors.primaryColor, themeColors.secondaryColor) : createCustomTheme("#ffffff", "#000000")}>
        {themeColors && <Router>
          <ToastContainer />
          <AppRootContainer />
        </Router>
        }
      </ThemeProvider>
    </Provider>
  );
}

export default App;
